<template>
  <transition name="fade-modal">
    <div
      class="wet-info-modal"
      :class="{'wet-info-modal-error': icon === 'error'}"
      v-if="opened"
    >
      <div class="wet-info-modal-bg" @click.prevent="$emit('close', false)"></div>
      <div class="wet-info-modal-container">
        <div class="wet-info-modal-icon" v-html="icons[icon]"></div>
        <div class="wet-info-modal-title">{{title}}</div>
        <div
          class="wet-info-modal-content empty-ph"
        ><slot name="content" class="wet-info-modal-content">
            <div class="empty-ph" v-html="sanitize(content)"></div>
          </slot></div>
        <div class="wet-info-modal-action">
          <slot name="actions">
            <a
              v-for="(action, index) in actions"
              :id="`wet-modal-button-${action.name}`"
              :key="index"
              class="empty-ph"
              :class="{
                'is-disabled': action.disabled
              }"
              :href="action.href || '#'"
              :title="action.title"
              @click.prevent="clickAction(action)"
            >{{action.title}}</a>
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import sanitizeHtml from 'sanitize-html';

export default {
  props: {
    opened: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: 'info',
    },
    title: String,
    content: String,
    actions: Array,
  },
  data() {
    return {
      icons: {
        marker: `\
        <svg id="icon-location" viewBox="0 0 24 24">
          <path d="M12 12a2 2 0 1 0-2-2 2 2 0 0 0 2 2zm0 2a4 4 0 1 1 4-4 4 4 0 0 1-4 4zm.78 7.17a29.16 29.16 0 0 0 3-2.73C18.43 15.64 20 12.75 20 10a8 8 0 0 0-16 0c0 2.75 1.57 5.64 4.23 8.44a29.16 29.16 0 0 0 3 2.73l.78.6zm4.44-1.36A31.13 31.13 0 0 1 14 22.74c-.38.3-.74.57-1.06.8l-.42.29a1 1 0 0 1-1.11 0l-.41-.29c-.32-.23-.68-.5-1.06-.8a31.13 31.13 0 0 1-3.2-2.92C3.8 16.68 2 13.37 2 10a10 10 0 0 1 20 0c0 3.37-1.8 6.68-4.77 9.81z"></path>
        </svg>\
        `,
        info: `\
        <svg id="icon-info" viewBox="0 0 24 24">
          <path d="M11.29 9.71a1 1 0 1 1 1.41 0 1 1 0 0 1-1.41 0zM13 16a1 1 0 0 1-2 0v-4a1 1 0 0 1 2 0zm-1 5a9 9 0 1 0-9-9 9 9 0 0 0 9 9zm0 2a11 11 0 1 1 11-11 11 11 0 0 1-11 11z"></path>
        </svg>\
        `,
        error: `\
        <svg x="0px" y="0px" viewBox="-22.5 -22.5 90 90" width="512px" height="512px">
          <g>
            <path d="M28.228,23.986L47.092,5.122c1.172-1.171,1.172-3.071,0-4.242c-1.172-1.172-3.07-1.172-4.242,0L23.986,19.744L5.121,0.88   c-1.172-1.172-3.07-1.172-4.242,0c-1.172,1.171-1.172,3.071,0,4.242l18.865,18.864L0.879,42.85c-1.172,1.171-1.172,3.071,0,4.242   C1.465,47.677,2.233,47.97,3,47.97s1.535-0.293,2.121-0.879l18.865-18.864L42.85,47.091c0.586,0.586,1.354,0.879,2.121,0.879   s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242L28.228,23.986z"/>
          </g>
        </svg>\
        `,
        map: `\
        <svg id="icon-location" viewBox="0 0 24 24">
          <path d="M12 12a2 2 0 1 0-2-2 2 2 0 0 0 2 2zm0 2a4 4 0 1 1 4-4 4 4 0 0 1-4 4zm.78 7.17a29.16 29.16 0 0 0 3-2.73C18.43 15.64 20 12.75 20 10a8 8 0 0 0-16 0c0 2.75 1.57 5.64 4.23 8.44a29.16 29.16 0 0 0 3 2.73l.78.6zm4.44-1.36A31.13 31.13 0 0 1 14 22.74c-.38.3-.74.57-1.06.8l-.42.29a1 1 0 0 1-1.11 0l-.41-.29c-.32-.23-.68-.5-1.06-.8a31.13 31.13 0 0 1-3.2-2.92C3.8 16.68 2 13.37 2 10a10 10 0 0 1 20 0c0 3.37-1.8 6.68-4.77 9.81z"></path>
        </svg>\
        `,
      },
    };
  },
  methods: {
    sanitize(html) {
      return sanitizeHtml(html, {
        allowedAttributes: {
          ...sanitizeHtml.defaults.allowedAttributes,
          '*': ['class', 'id'],
        },
      });
    },
    clickAction({ event, disabled }) {
      if (disabled) return false;

      if (typeof event === 'function') {
        event();
      } else if (typeof event === 'string' && event === 'close') {
        this.$emit('close');
      } else if (Array.isArray(event)) {
        event.forEach((el) => {
          if (typeof el === 'string' && el === 'close') {
            this.$emit('close');
          } else if (typeof el === 'function') {
            el();
          }
        });
      }
      return true;
    },
  },
};
</script>
<style lang="scss">
  .wet-info-modal {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    height: var(--view-height);
    width: 100vw;
    overflow: hidden;
    z-index: 9999;
    backdrop-filter: blur(10px);
    transition: all ease-in-out .3s;
  }
  .wet-info-modal-bg {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: radial-gradient(circle, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.9) 100%);
  }
  .wet-info-modal-container {
    position: relative;
    z-index: 1;
    flex: none;
    max-width: 700px;
    width: calc(100% - 2rem);
    background-color: white;
    border-radius: 1rem;
    display: flex;
    flex-wrap: wrap;
    padding: 55px 0 0 0;
  }
  @media only screen and (max-width: 768px) {
    .wet-info-modal-container {
      font-size: 1rem;
      max-width: 500px;
    }
  }
  .wet-info-modal-icon {
    position: absolute;
    top: -30px;
    left: calc(50% - 35px);
    width: 70px;
    height: 70px;
    border: 5px solid #fff;
    border-radius: 50%;
    background-color: var(--secondary);
    color: white;
    box-shadow: 0 0 1px 0 #a0a6ad;
  }
  .wet-info-modal-icon svg {
    width: 28px;
    height: 28px;
    top: calc(50% - 14px);
    left: calc(50% - 14px);
    position: absolute;
  }
  .wet-info-modal-error .wet-info-modal-icon {
    background-color: red;
  }
  .wet-info-modal-title {
    font-weight: 700;
    flex: none;
    width: 100%;
    margin: 0 auto 10px;
    color: var(--secondary);
    text-align: center;
  }
  .wet-info-modal-content {
    flex: none;
    width: 100%;
    padding: 0 1rem;
    margin-bottom: 1rem;
    max-height: calc(var(--view-height) - 15rem);
    overflow: auto;
  }
  .wet-info-modal-action {
    display: flex;
    flex: none;
    width: 100%;
    background-color: var(--secondary);
    color: white;
    font-weight: 700;
    border-radius: 0 0 1rem 1rem;
    overflow: hidden;
  }
  .wet-info-modal-action .is-disabled {
    background-color: #{"rgb(var(--secondary-rgb-darken))"};
    cursor: not-allowed;
  }
  .wet-info-modal-action a {
    text-align: center;
    flex: 1;
    padding: .5rem;
  }
  .wet-info-modal-action a:not(:last-child) {
    border-right: 1px solid white;
  }
</style>
